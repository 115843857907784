import logo from './Geniuses_Logo.png';
import './App.css';
import QRCode from 'qrcode.react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Her kommer Geniuses Esport.
        </p>
        <div className="support_wrapper section__padding section__margin">
          <div className="support-container section__padding">
            <div className="support-container_header">
              <p>Støtt oss med</p>
              <h3>Grasrotandelen</h3>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Norsk_Tipping_%28st%C3%A5ende_logo%29.svg/1200px-Norsk_Tipping_%28st%C3%A5ende_logo%29.svg.png"
                alt=""
                className="Norsk-Tipping-Logo"
              />
            </div>
            <div className="support-container_description">
              <p>
                Grasrotandelen gir deg som spiller hos Norsk Tipping mulighet
                til å bestemme hvem som skal motta noe av overskuddet til Norsk
                Tipping. <br /> Hver gang du spiller går inntil 7 prosent av din
                spillinnsats til den grasrotmottakeren du velger - uten at det
                går utover din innstats, premie eller vinnersjanse. <br /> Vi
                oppfordrer deg til å støtte oss i Geniuses Esports
              </p>
            </div>
            <div className="support-container_QR">
              <QRCode value="https://www.norsk-tipping.no/grasrotandelen/for-lag-og-foreninger/vervebrev/926890271" />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
